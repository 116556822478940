export const addSearch = (data) => {

    return {
        type: 'ADD_SEARCH',
        data
    }

}

export const deleteSearch = (data) => {

    return {
        type: 'DELETE_SEARCH',
        data
    }

}